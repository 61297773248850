
import { Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { IAppUser } from "@/types/AppUser";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import FooterComponent from "@/components/FooterComponent.vue";

@Options({
  name: "UserRegistration",
  components: {
    FooterComponent,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
  },
  data: function () {
    return {
      loading: false,
      message: "",
      schema: yup.object().shape({
        newPassword: yup
          .string()
          .required("Password is required!")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_=+()[\]{}|\\;:'",.<>?/~`])(?=.{8,})/,
            "Your password must meet the below requirements"
          ),
        confirmPassword: yup
          .string()
          .required("Password confirmation is required.")
          .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
      }),
    };
  },
})
export default class UserRegistration extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  token = "";
  userId = 0;
  message = "";
  loading = false;
  showPassword = false;

  async created(): Promise<void> {
    if (this.$route.query.t) {
      this.token = this.$route.query.t.toString().replace(/ /g, "+");
    }

    if (this.$route.query.i) {
      this.userId = Number(this.$route.query.i);
    }
  }

  async showNotification(msg: string) {
    const modal = await (this.$refs["confirmDialogue"] as any).show({
      title: "Attention!",
      message: msg,
      okButton: "Ok",
      cancelButton: "Cancel",
      icon: "success",
      type: "notification",
    });
    if (modal) {
      this.$router.push({
        path: "/account/login",
        name: "login",
      });
    }
  }

  newPassword = "";
  password_length = 0;
  contains_eight_characters = false;
  contains_number = false;
  contains_uppercase = false;
  contains_lowercase = false;
  contains_special_character = false;
  valid_password = false;
  checkPassword() {
    this.password_length = this.newPassword.length;
    if (this.password_length >= 8) {
      this.contains_eight_characters = true;
    } else {
      this.contains_eight_characters = false;
    }

    this.contains_number = /\d/.test(this.newPassword);
    this.contains_uppercase = /[A-Z]/.test(this.newPassword);
    this.contains_lowercase = /[a-z]/.test(this.newPassword);
    this.contains_special_character = /^(?=.*[!@#$%^&*\-_=+()[\]{}|\\;:'",.<>?/~`]).+$/.test(
      this.newPassword
    );

    if (
      this.contains_eight_characters &&
      this.contains_special_character &&
      this.contains_uppercase &&
      this.contains_lowercase &&
      this.contains_number
    ) {
      this.valid_password = true;
    } else {
      this.valid_password = false;
    }
  }

  resetPassFlags() {
    this.newPassword = "";
    this.password_length = 0;
    this.contains_eight_characters = false;
    this.contains_number = false;
    this.contains_uppercase = false;
    this.contains_lowercase = false;
    this.contains_special_character = false;
    this.valid_password = false;
  }

  changePassword(appUser: IAppUser): void {
    this.message = "";
    this.loading = true;

    appUser.token = this.token;
    appUser.userId = this.userId;

    this.workForceService
      .postOrPut<void>("/Account/resetPassword", appUser as IAppUser, undefined)
      .then((response: any) => {
        if (!response.isError) {
          this.loading = false;
          this.showNotification("Password changed successfully!");
          this.resetPassFlags();
        } else if (response.isSuccess == false) {
          this.message = response.errors;
          this.loading = false;
        } else {
          this.loading = false;
          this.message = response.errors;
        }
      });
  }
}
